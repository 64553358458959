/* cart */
#cart {
  display: inline-block;

  .dropdown-menu {
    right: 1rem;
    width: 92%;
    padding: 1rem 2rem;
    box-shadow: $shadow-4;

    @include media-breakpoint-up(sm) {
      width: auto;
      min-width: 22rem;
    }
  }

  .cart-item {
    font-size: $font-size-small;
  }
}

.top-bar {
  .dropdown-menu {
    border-radius: 0;
  }
}


.backdrop {
  background: rgba($black, 0.2);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-backdrop;
}

.show {
  .backdrop {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.cart-item {
  padding: 1rem;
  border-bottom: $border;

  &:last-child {
    border-bottom: none;
  }
}
