/* stylelint-disable unit-whitelist, at-rule-empty-line-before */

// Settings: Global variables, site-wide settings, config switches, etc.
@import "settings/bootstrap-custom";
@import "bootstrap/scss/variables";
$border-dark: 1px solid $gray-dark;
$border: 1px solid $gray-lighter;
$z-index-top-bar: 1000;
$z-index-dropdown-menu: 800;
$z-index-backdrop: 600;

$font-size-special: 32px !default;
$font-size-huge: 1.75rem !default; // 28px
$font-size-large: 1.25rem !default; // 20px
$font-size-small: 0.875rem !default; // 14px
$font-size-tiny: 0.75rem !default; // 12px

// Elevation & shadows
$shadow-color: rgba($black, 0.1);
$shadow-1: 0 1px 2px 0 $shadow-color !default;
$shadow-2: 0 2px 4px 0 $shadow-color !default;
$shadow-3: 0 3px 6px 0 $shadow-color !default;
$shadow-4: 0 4px 8px 0 $shadow-color !default;

// Tools: Site-wide mixins and functions.
@import "bootstrap/scss/mixins";
@import "tools/layout";
@import "tools/background";

// Generic: Low-specificity, far-reaching rulesets (e.g. resets).
@import "bootstrap/scss/normalize";
@import "bootstrap/scss/print";

// Elements: Unclassed HTML elements (e.g. a {}, blockquote {}, address {}).
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

.title {
  // font-size: $font-size-huge;
  margin-top: 1rem;
  margin-bottom: 1rem;
  // text-transform: uppercase;
}

.subheading {
  font-size: $font-size-large;
  font-weight: bold;
  text-transform: uppercase;
}

.panel-title {
  font-size: 1rem;
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

button:focus {
  outline: none !important;
}

// p:last-child {
//   margin-bottom: 0;
// }

hr {
  border-top: none;
  border-bottom: $border;
}

.slash:last-child {
  display: none;
}

// Components: Discrete, complete chunks of UI (e.g. .carousel {}). This is the one layer that inuitcss doesn’t get involved with.

@import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";

@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
// @import "bootstrap/scss/responsive-embed";
@import "bootstrap/scss/close";

// Components w/ JavaScript
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";

@import "components/oc-logo";
@import "components/oc-top";
@import "components/oc-cart";
@import "components/oc-product-list";
@import "components/oc-footer";
@import "components/opencart";

// @import "components/button";

.slash {
  margin: 0 0.25rem;

  @include media-breakpoint-up(md) {
    margin: 0 1rem;
  }
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: $z-index-top-bar;

  // @include media-breakpoint-up(md) {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: $white;
  // }
}

.banner {
  // @include overlay-after();

  margin-bottom: 1rem;
  // margin-top: -72px; // height of top-bar
}

.category {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;

  a {
    color: black;
    // font-size: 12px;
    // text-transform: uppercase;
    // font-weight: bold;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.common-home {
  #top {
    .btn-link {
      // color: $white;
    }
  }

  .featured-title {
    display: none;
  }
}

.btn-secondary {
  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $gray;
    border: 1px solid $gray;
    color: $white;
  }
}

.breadcrumb {
  margin-bottom: 0;
  // display: none; // hide breadcrumb for hot-fix
}

// Utility classes
@import "bootstrap/scss/utilities/background";
@import "bootstrap/scss/utilities/clearfix";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/float";
@import "bootstrap/scss/utilities/spacing";
@import "bootstrap/scss/utilities/text";
@import "bootstrap/scss/utilities/visibility";

// @import "utilities/transition";
