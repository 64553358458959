/* footer */
footer {
  font-size: $font-size-small;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  // background-color: $gray-dark;
  // border-top: $border;
  // color: $gray-lighter;

  h5 {
    font-weight: bold;
    color: $white;
  }
}
