/* stylelint-disable unit-whitelist, at-rule-empty-line-before */

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;

// Create grayscale
$gray-dark:                 #212121;
$gray:                      #7d7d7d;
$gray-light:                #919191;
$gray-lighter:              #d6d6d6;
// $gray-lightest:             #f7f7f9;

// Reassign color vars to semantic color scheme
$brand-primary:        $gray;
// $brand-secondary:      #cfd9e6;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// $spacer:   0.5rem;

// Body
//
// Settings for the `<body>` element.
$body-color: $gray-dark;
// $body-bg: $gray-lighter;


// Links
//
// Style anchor elements.

$link-color:            $gray-light;
// $link-decoration:       underline;
// $link-hover-color:      darken($link-color, 15%) !default;
// $link-hover-decoration: underline;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$grid-gutter-width-base: 30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: 60px,
  lg: 60px,
  xl: 60px
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1140px
);


// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 'Archivo Narrow', Helvetica, Arial, sans-serif, 微軟正黑體;

$font-weight-normal: 400;
$font-weight-bold: 400;

$font-size-h1: 1.7rem;
$font-size-h2: 1.5rem;
$font-size-h3: 1.25rem;
$font-size-h4: 1.2rem;
$font-size-h5: 1.1rem;
$font-size-h6: 1rem;

// $headings-font-family: Helvetica, Arial, sans-serif, 微軟正黑體;
$headings-font-weight: $font-weight-bold;

// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg:         (4 / 3) !default;
// $line-height-sm:         1.5 !default;

$border-radius:          0;
$border-radius-lg:       0;
$border-radius-sm:       0;


// Forms
// $input-padding-x:                .50rem !default;
// $input-padding-y:                .25rem !default;

// $input-border-color:             transparent;

// $input-border-radius:            0;
// $input-border-radius-lg:         0;
// $input-border-radius-sm:         0;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.
$btn-font-weight: $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;

// $btn-primary-color:              $white;
// $btn-primary-bg:                 $brand-primary;
// $btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $gray-light;
// $btn-secondary-bg:               $white;
$btn-secondary-border:           $gray-lighter;

// Breadcrumbs

// $breadcrumb-padding-y:          .75rem !default;
// $breadcrumb-padding-x:          1rem !default;
// $breadcrumb-item-padding:       .5rem !default;

$breadcrumb-bg:                 $white;
$breadcrumb-padding-x: 0;
// $breadcrumb-divider-color:      $gray-light !default;
// $breadcrumb-active-color:       $gray-light !default;
// $breadcrumb-divider:            "/" !default;

// Cards

// $card-spacer-x:            1.25rem !default;
// $card-spacer-y:            .75rem !default;
// $card-border-width:        1px !default;
// $card-border-radius:       $border-radius !default;
// $card-border-color:        rgba($black,.125) !default;
// $card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:              $white;
// $card-bg:                  $white !default;

// $card-link-hover-color:    $white !default;

// $card-img-overlay-padding: 1.25rem !default;

// $card-deck-margin:          ($grid-gutter-width-base / 2) !default;

// $card-columns-count:        3 !default;
// $card-columns-gap:          1.25rem !default;
// $card-columns-margin:       $card-spacer-y !default;

