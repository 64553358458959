.product-layout{

}

/* product list */
.product-thumb {
  margin-bottom: 1rem;
  overflow: auto;
  text-align: center;

  /* &:last-child {
    margin-bottom: 0;
  } */

  .image {
    img {
      width: 100%;
    }

    margin-bottom: 1rem;
  }

  .caption {

  }

  h2.price {
    margin: 0;
  }

  .price {

    &h2{
      margin: 0;
    }
  }

  .price-new {

  }

  .price-tax {
    font-size: 80%;
    display: block;
  }
}

.price-old {
  color: $gray-light;
  text-decoration: line-through;
  margin-left: 0.5rem;
}

.product-despction {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  a {
    color: $brand-info;
  }
}

.product-additional-images {
  img {
    width: 100%;
  }
}

.product-thumb .image {
  text-align: center;
}
.product-thumb .image a {
  display: block;
}
.product-thumb .image a:hover {
  opacity: 0.8;
}
.product-grid .product-thumb .image {
  float: none;
}
@media (min-width: 767px) {
.product-list .product-thumb .image {
  float: left;
  padding: 0 15px;
}
}
.product-thumb h4 {
  font-weight: bold;
}
.product-thumb .caption {
  padding: 0 20px;
}
.product-list .product-thumb .caption {
  margin-left: 230px;
}
@media (max-width: 1200px) {
.product-grid .product-thumb .caption {
  min-height: 210px;
  padding: 0 10px;
}
}
@media (max-width: 767px) {
.product-list .product-thumb .caption {
  min-height: 0;
  margin-left: 0;
  padding: 0 10px;
}
.product-grid .product-thumb .caption {
  min-height: 0;
}
}
.product-thumb .rating {
  padding-bottom: 10px;
}
.rating .fa-stack {
  font-size: 8px;
}
.rating .fa-star-o {
  color: #999;
  font-size: 15px;
}
.rating .fa-star {
  color: #FC0;
  font-size: 15px;
}
.rating .fa-star + .fa-star-o {
  color: #E69500;
}
.product-thumb .button-group {
  border-top: 1px solid #ddd;
  background-color: #eee;
  overflow: auto;
}
.product-list .product-thumb .button-group {
  border-left: 1px solid #ddd;
}
@media (max-width: 768px) {
  .product-list .product-thumb .button-group {
    border-left: none;
  }
}
.product-thumb .button-group button {
  width: 60%;
  border: none;
  display: inline-block;
  float: left;
  background-color: #eee;
  color: #888;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.product-thumb .button-group button + button {
  width: 20%;
  border-left: 1px solid #ddd;
}
.product-thumb .button-group button:hover {
  color: #444;
  background-color: #ddd;
  text-decoration: none;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .product-thumb .button-group button, .product-thumb .button-group button + button {
    width: 33.33%;
  }
}
@media (max-width: 767px) {
  .product-thumb .button-group button, .product-thumb .button-group button + button {
    width: 33.33%;
  }
}
.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
}
.thumbnails > li {
  margin-left: 20px;
}
.thumbnails {
  margin-left: -20px;
}
.thumbnails > img {
  width: 100%;
}
.image-additional a {
  margin-bottom: 20px;
  padding: 5px;
  display: block;
  border: 1px solid #ddd;
}
.image-additional {
  max-width: 78px;
}
.thumbnails .image-additional {
  float: left;
  margin-left: 20px;
}

@media (min-width: 1200px) {
  #content .col-lg-2:nth-child(6n+1),
  #content .col-lg-2:nth-child(6n+1),
  #content .col-lg-3:nth-child(4n+1),
  #content .col-lg-4:nth-child(3n+1),
  #content .col-lg-6:nth-child(2n+1) {
    clear:left;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #content .col-md-2:nth-child(6n+1),
  #content .col-md-2:nth-child(6n+1),
  #content .col-md-3:nth-child(4n+1),
  #content .col-md-4:nth-child(3n+1),
  #content .col-md-6:nth-child(2n+1) {
    clear:left;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #content .col-sm-2:nth-child(6n+1),
  #content .col-sm-2:nth-child(6n+1),
  #content .col-sm-3:nth-child(4n+1),
  #content .col-sm-4:nth-child(3n+1),
  #content .col-sm-6:nth-child(2n+1) {
    clear:left;
  }
}