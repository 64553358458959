/*
  Render flex container whose items will algin center
*/
@mixin flex-center ($flow: row, $inline: true) {
  @if ($inline) {
    display: inline-flex;
  }

  @else {
    display: flex;
  }

  flex-flow: $flow;
  align-items: center;
  justify-content: center;
}

/*
  Render flex item like <div> element which will not grow, shrink and auto
  resize based on the content.
*/
@mixin flex-solid() {
  flex: 0 0 auto;
}

/*
  Render expandable flex item
*/
@mixin flex-fluid() {
  flex: 1 1 0;
}

/// Vertically centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/// Horizontally centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// Absolutely centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
