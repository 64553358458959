/* top */
#top {
  // width: 100%;
}
#top #form-currency .currency-select,
#top #form-language .language-select {
  text-align: left;
}
#top #form-currency .currency-select:hover,
#top #form-language .language-select:hover {
  text-shadow: none;

  background-color: #229ac8;
  background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
  background-repeat: repeat-x;
  color: #ffffff;
}
#top .btn-link, #top-links li, #top-links a {
  color: #888;
  text-shadow: 0 1px 0 #FFF;
  text-decoration: none;
}
#top .btn-link:hover, #top-links a:hover {
  color: #444;
}
#top-links .dropdown-menu a {
  text-shadow: none;
}
#top-links .dropdown-menu a:hover {
  color: #FFF;
}
#top .btn-link strong {
  font-size: 14px;
        line-height: 14px;
}
#top-links {

}
#top-links a + a {
  margin-left: 15px;
}