/* Fix some bootstrap issues */
button {
  outline: none !important;
}
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
  display: inline;
}
.nav-tabs {
  margin-bottom: 15px;
}
div.required label:before {
  content: '* ';
  color: #F00;
  font-weight: bold;
}
/* search */
#search {
  margin-bottom: 10px;
}
#search .input-lg {
  height: 40px;
  line-height: 20px;
  padding: 0 10px;
}
#search .btn-lg {
  font-size: 15px;
  line-height: 18px;
  padding: 10px 35px;
  text-shadow: 0 1px 0 #FFF;
}

/* menu */
#menu {
  background-color: #229ac8;
  background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
  background-repeat: repeat-x;
  border-color: #1f90bb #1f90bb #145e7a;
  margin-top: 45px;
  min-height: 40px;
}
#menu .nav > li > a {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 15px 10px 15px;
  min-height: 15px;
  background-color: transparent;
}
#menu .nav > li > a:hover, #menu .nav > li.open > a {
  background-color: rgba(0, 0, 0, 0.1);
}
#menu .dropdown-menu {
  padding-bottom: 0;
}
#menu .dropdown-inner {
  display: table;
}
#menu .dropdown-inner ul {
  display: table-cell;
}
#menu .dropdown-inner a {
  min-width: 160px;
  display: block;
  padding: 3px 20px;
  clear: both;
  line-height: 20px;
  color: #333333;
  font-size: 12px;
}
#menu .dropdown-inner li a:hover {
  color: #FFFFFF;
}
#menu .see-all {
  display: block;
  margin-top: 0.5em;
  border-top: 1px solid #DDD;
  padding: 3px 20px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 3px 3px;
  font-size: 12px;
}
#menu .see-all:hover, #menu .see-all:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #229ac8;
  background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
  background-repeat: repeat-x;
}
#menu #category {
  float: left;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
#menu .btn-navbar {
  font-size: 15px;
  font-stretch: expanded;
  color: #FFF;
  padding: 2px 18px;
  float: right;
  background-color: #229ac8;
  background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
  background-repeat: repeat-x;
  border-color: #1f90bb #1f90bb #145e7a;
}
#menu .btn-navbar:hover, #menu .btn-navbar:focus, #menu .btn-navbar:active, #menu .btn-navbar.disabled, #menu .btn-navbar[disabled] {
  color: #ffffff;
  background-color: #229ac8;
}
@media (min-width: 768px) {
  #menu .dropdown:hover .dropdown-menu {
    display: block;
  }
}
@media (max-width: 767px) {
  #menu {
    border-radius: 4px;
  }
  #menu div.dropdown-inner > ul.list-unstyled {
    display: block;
  }
  #menu div.dropdown-menu {
    margin-left: 0 !important;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  #menu .dropdown-inner {
    display: block;
  }
  #menu .dropdown-inner a {
    width: 100%;
    color: #fff;
  }
  #menu .dropdown-menu a:hover,
  #menu .dropdown-menu ul li a:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  #menu .see-all {
    margin-top: 0;
    border: none;
    border-radius: 0;
    color: #fff;
  }
}
/* content */
#content {
  min-height: 600px;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }
}

/* list group */
.list-group a {
  border: 1px solid #DDDDDD;
  color: #888888;
  padding: 8px 12px;
}
.list-group a.active, .list-group a.active:hover, .list-group a:hover {
  color: #444444;
  background: #eeeeee;
  border: 1px solid #DDDDDD;
  text-shadow: 0 1px 0 #FFF;
}
/* carousel */
.carousel-caption {
  color: #FFFFFF;
  text-shadow: 0 1px 0 #000000;
}
.carousel-control .icon-prev:before {
  content: '\f053';
  font-family: FontAwesome;
}
.carousel-control .icon-next:before {
  content: '\f054';
  font-family: FontAwesome;
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
    #column-left  .product-layout .col-md-3 {
       width: 100%;
    }

  #column-left + #content .product-layout .col-md-3 {
       width: 50%;
    }

  #column-left + #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }

    #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }
}

/* fixed product layouts used in left and right columns */
#column-left .product-layout, #column-right .product-layout {
  width: 100%;
}

/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
  min-width: 50px;
}

/* Missing focus and border color to overwrite bootstrap */
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}
